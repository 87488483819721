import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Link } from "gatsby";
import Img from 'gatsby-image';
import { useLocation } from "@reach/router";
import styled from 'styled-components';
const StyledRoot = styled.header`
  @media (max-width: 1024px) {
    .mobile-menu {
      position: initial;
    }
  }
`;

const Header = ({ settings }) => {
  const [showMobileMenu, toggleMobileMenu] = useState(false);
  const location = useLocation();
  const { _rawLogo, title } = settings;

  useEffect(() => {
    toggleMobileMenu(false);
  }, [location]);

  const onSowMobileMenu = (e) => {
    e.preventDefault();
    toggleMobileMenu(true);
  };

  const onClose = (e) => {
    e.preventDefault();
    toggleMobileMenu(false);
  };

  return (
    <StyledRoot className="row">
      <div className="col-xs-18 col-md-offset-1 col-md-5">
        <div className="row">
          <div className="col-xs-12">
            <div className="header-logo">
              <a href="/">
                <img
                  className="img-fluid"
                  src={_rawLogo.asset.url}
                  alt={title}
                />
              </a>
            </div>
          </div>
          <div className="col-xs-6">
            <a
              id="menuToggle"
              href="#"
              className="menu-toggle hide-md"
              onClick={onSowMobileMenu}
            >
              Menu
            </a>
          </div>
        </div>
      </div>
      <div className="col-xs-18 col-md-11 mobile-menu">
        <div
          id="menuNav"
          className={cx('header-navigation', { 'menu-active': showMobileMenu })}
        >
          <nav>
            <h2 id="mainmenulabel" className="visuallyhidden">
              Main Menu
            </h2>
            <a href="#" id="menuClose" className="hide-md" onClick={onClose}>
              Close Menu
            </a>
            <Link to="/how_it_works">How It Works</Link>
            <Link to="/real_results">Real Results</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/support">Support</Link>
            <a href="/login">Login</a>
            <Link to="/pricing" className="button button-blue">
              Try It Free
            </Link>
          </nav>
        </div>
      </div>
    </StyledRoot>
  );
};

export default Header;
