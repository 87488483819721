import React from "react";
import { Link } from "gatsby";

const CTALink = props => {
  let link = props.route || props.link || "#";
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = '/' + props.landingPageRoute.slug.current;
  }

  // External
  if (props.link) {
    return (
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.title}
      </a>
    );
  }

  return (
    <Link to={link}>
      {props.title}
    </Link>
  );
};

export default CTALink;
