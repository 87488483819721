import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SEO from "./SEO";
import "../styles/_all.scss";

const Layout = ({
  title,
  description,
  image,
  settings,
  footer,
  hideCta,
  children,
}) => {
  return (
    <div className="public-wrap">
      <SEO
        siteName={settings.title}
        title={title || settings.title}
        description={description}
        image={image || settings._rawOpenGraph.image}
      />
      <Header settings={settings} />
      <>{children}</>
      <Footer hideCta={hideCta} settings={settings} footer={footer} />
    </div>
  );
};

export default Layout;
